import ReactOnRails from 'react-on-rails';
import LocalTime from "local-time"
import NavBar from '../bundles/layout/NavBar';
import mixpanel from "mixpanel-browser";
import jquery from "jquery";

window.mixpanelToken = document.querySelector('meta[name="mixpanel"]').getAttribute('content');
mixpanel.init(window.mixpanelToken); 

ReactOnRails.register({
  NavBar,
});
LocalTime.start();

window.jQuery = jquery;
window.$ = jquery;

$(function() {
  const trackableLinks = $('.trackable-link');
  $(trackableLinks).on( "click", function(e) {
    const text = e.currentTarget.text;
    mixpanel.track(`Click link "${text}"`);
  });
});